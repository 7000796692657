import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class IconRow extends Component {
    static propTypes = {
        icons: PropTypes.object.isRequired,
    };

    render() {
        const {
            icons
        } = this.props;

        return (
            <div className="c-icon-row">
                {
                    Object.entries(icons).map(([icon, link]) => {
                        return (
                            <a className={`c-icon-row__link c-icon-row__link--${icon}`} href={link} target="_blank" rel="noopener noreferrer" key={icon}>
                                <i className={`c-icon-row__icon fab fa-${icon}`}></i>
                            </a>
                        );
                    })
                }
            </div>
        )
    }
}