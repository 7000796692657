import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Section extends Component {
    static propTypes = {
        fullScreen: PropTypes.bool,
        dark: PropTypes.bool,
        bgImage: PropTypes.string,
        imgAlt: PropTypes.string
    };

    render() {
        const {
            children,
            dark,
            fullScreen,
            bgImage,
            imgAlt
        } = this.props;

        const darkClass = dark ? "c-section--dark" : '';
        const fullScreenClass = fullScreen ? "c-section--full-screen" : '';
        const bgImageClass = bgImage !== '' ? "c-section--with-image" : '';

        return (
            <section className={`c-section ${darkClass} ${fullScreenClass} ${bgImageClass}`}>
                <div className="c-section__children">
                    {children}
                </div>
                {bgImage !== '' &&
                    <img className="c-section__bg-image" alt={imgAlt} src={`/assets/img/${bgImage}`} />
                }
            </section>
        )
    }
}