import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Avatar extends Component {
    static propTypes = {
        picture: PropTypes.string.isRequired,
        intro: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        positions: PropTypes.array.isRequired,
    };

    render() {
        const {
            picture,
            intro,
            name,
            positions
        } = this.props;

        return (
            <div className="c-avatar">
                <img className="c-avatar__picture" alt={name} src={`/assets/img/${picture}`}/>
                <div className="c-avatar__info">
                    {intro}
                    <h1 className="c-avatar__name">{name}</h1>
                    <h2 className="c-avatar__description">
                        {
                            positions.map((position, i) => {
                                return <span className="c-avatar__description-item" key={i}>{position}</span>
                            })
                        }
                    </h2>
                </div>
            </div>
        )
    }
}