import React, {Component} from 'react';

import {Avatar} from './components/avatar/Avatar'
import {IconRow} from './components/icon-row/IconRow'
import {Section} from './components/section/Section'

import './App.scss';

class App extends Component {
    render() {
        const name = "Arnau Pujol";

        return (
            <div className="t-page">
                <Section fullScreen={true} dark={true} bgImage="arnau-pujol__hero-image.jpg" imgAlt={`${name}`}>
                    <Avatar picture="arnau-pujol.jpg" intro="Hi! I'm" name={name}
                            positions={['Product Owner','Happiness Officer']}/>
                    <IconRow icons={{
                        "linkedin": "https://www.linkedin.com/in/arnaupujol/",                
                    }}/>
                </Section>
            </div>
        );
    }
}

export default App;
